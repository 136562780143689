import generateAreaProperties from '../../utils/statusOfFlat';


const thirdFloor = {
  name: 'thirdFloor',
  key: 'thirdFloor',
  src: 'flats/trzecie-piętro_Kaktusowa.png',
  areas: [
    {
      id: 'M34A',
      title: 'M34A',
      shape: 'rect',
      name: '34',
      href: 'flats/M34A_Kaktusowa.pdf',
      ...await generateAreaProperties('M34A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [696, 42, 908, 383],
    },
    {
      id: 'M35A',
      title: 'M35A',
      shape: 'poly',
      name: '35',
      href: 'flats/M35A_Kaktusowa.pdf',
      ...await generateAreaProperties('M35A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [901, 447, 1195, 447, 1195, 706, 1047, 707, 1047, 644, 901, 646],
    },
    {
      id: 'M36A',
      title: 'M36A',
      shape: 'poly',
      name: '36',
      href: 'flats/M36A_Kaktusowa.pdf',
      ...await generateAreaProperties('M36A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [1188, 445, 1234, 445, 1231, 375, 1320, 375, 1321, 447, 1402, 447, 1397, 375, 1534, 373, 1534, 644, 1335, 644, 1335, 707, 1191, 707],
    },
    {
      id: 'M37A',
      title: 'M37A',
      shape: 'poly',
      name: '37',
      href: 'flats/M37A_Kaktusowa.pdf',
      ...await generateAreaProperties('M37A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [1189, 381, 1186, 318, 1316, 321, 1315, 43, 1441, 43, 1443, 107, 1534, 108, 1534, 379, 1407, 381, 1407, 450, 1318, 451, 1316, 381],
    },
    {
      id: 'M38A',
      title: 'M38A',
      shape: 'poly',
      name: '38',
      href: 'flats/M38A_Kaktusowa.pdf',
      ...await generateAreaProperties('M38A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [1097, 382, 1095, 44, 1320, 44, 1320, 323, 1196, 325, 1196, 382],
    },
    {
      id: 'M39A',
      title: 'M39A',
      shape: 'rect',
      name: '39',
      href: 'flats/M39A_Kaktusowa.pdf',
      ...await generateAreaProperties('M39A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [901, 382, 1100, 42],
    },
    {
      id: 'M40A',
      title: 'M40A',
      shape: 'rect',
      name: '40',
      href: 'flats/M40A_Kaktusowa.pdf',
      ...await generateAreaProperties('M40A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [507, 382, 698, 43],
    },
    {
      id: 'M41A',
      title: 'M41A',
      shape: 'poly',
      name: '41',
      href: 'flats/M41A_Kaktusowa.pdf',
      ...await generateAreaProperties('M41A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [413, 383, 509, 381, 509, 44, 284, 42, 284, 326, 410, 328],
    },
    {
      id: 'M42A',
      title: 'M42A',
      shape: 'poly',
      name: '42',
      href: 'flats/M42A_Kaktusowa.pdf',
      ...await generateAreaProperties('M42A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [418, 382, 418, 319, 291, 321, 290, 43, 161, 43, 161, 108, 70, 110, 70, 384, 201, 383, 201, 453, 291, 453, 290, 382],
    },
    {
      id: 'M43A',
      title: 'M43A',
      shape: 'poly',
      name: '43',
      href: 'flats/M43A_Kaktusowa.pdf',
      ...await generateAreaProperties('M43A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [374, 446, 372, 376, 284, 376, 284, 446, 202, 446, 204, 376, 70, 376, 70, 645, 271, 644, 271, 707, 416, 710, 415, 448],
    },
    {
      id: 'M44A',
      title: 'M44A',
      shape: 'poly',
      name: '44',
      href: 'flats/M44A_Kaktusowa.pdf',
      ...await generateAreaProperties('M44A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [413, 448, 703, 445, 706, 645, 557, 645, 559, 709, 415, 709],
    },
  ] };

export default thirdFloor;
