import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebase = {
  apiKey: 'AIzaSyAuSgU4ePichZVa42pU8v0X4L-Okeol9AY',
  authDomain: 'fpdevelopment-7e80b.firebaseapp.com',
  projectId: 'fpdevelopment-7e80b',
  storageBucket: 'fpdevelopment-7e80b.appspot.com',
  messagingSenderId: '757251196416',
  appId: '1:757251196416:web:67963d75748520a164bae5',
  measurementId: 'G-SQXQC19SY1',
  databaseURL: 'https://europe-central2.firebaseio.com',
};

const app = initializeApp(firebase);
export const auth = getAuth(app);
export const db = getFirestore(app);
