import generateAreaProperties from '../../utils/statusOfFlat';

const groundFloor = {
  name: 'groundFloor',
  key: 'groundFloor',
  src: 'flats/parter_Kaktusowa.png',
  areas: [
    {
      id: 'M1A',
      title: 'M1A',
      shape: 'rect',
      name: '1',
      stayHighlighted: true,
      href: 'flats/M1A_Kaktusowa.pdf',
      ...await generateAreaProperties('M1A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [677, 94, 881, 366],
    },
    {
      id: 'M2A',
      title: 'M2A',
      shape: 'rect',
      name: '2',
      href: 'flats/M2A_Kaktusowa.pdf',
      target: '_blank',
      ...await generateAreaProperties('M2A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [483, 96, 682, 367],
    },
    {
      id: 'M3A',
      title: 'M3A',
      shape: 'poly',
      name: '3',
      href: 'flats/M3A_Kaktusowa.pdf',
      ...await generateAreaProperties('M3A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [390, 367, 481, 367, 485, 95, 260, 96, 265, 313, 388, 311],
    },
    {
      id: 'M4A',
      title: 'M4A',
      shape: 'poly',
      name: '4',
      href: 'flats/M4A_Kaktusowa.pdf',
      ...await generateAreaProperties('M4A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [49, 94, 269, 94, 265, 312, 396, 307, 398, 367, 267, 367, 269, 440, 179, 435, 179, 368, 49, 367],
    },
    {
      id: 'M5A',
      title: 'M5A',
      shape: 'poly',
      name: '5',
      href: 'flats/M5A_Kaktusowa.pdf',
      ...await generateAreaProperties('M5A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [396, 432, 352, 432, 352, 361, 264, 361, 264, 434, 184, 434, 182, 361, 49, 361, 49, 438, 51, 633, 396, 628],
    },
    {
      id: 'M6A',
      title: 'M6A',
      shape: 'rect',
      name: '6',
      href: 'flats/M6A_Kaktusowa.pdf',
      ...await generateAreaProperties('M6A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [396, 431, 597, 629],
    },
    {
      id: 'M11A',
      title: 'M11A',
      shape: 'rect',
      name: '11',
      href: 'flats/M11A_Kaktusowa.pdf',
      ...await generateAreaProperties('M11A'),
      coords: [968, 628, 1174, 432],
    },
    {
      id: 'M10A',
      title: 'M10A',
      shape: 'poly',
      name: '10',
      href: 'flats/M10A_Kaktusowa.pdf',
      ...await generateAreaProperties('M10A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [1175, 628, 1514, 628, 1514, 359, 1379, 360, 1381, 432, 1301, 435, 1300, 362, 1213, 360, 1211, 434, 1174, 435, 1172, 503, 1174, 563],
    },
    {
      id: 'M9A',
      title: 'M9A',
      shape: 'poly',
      name: '9',
      href: 'flats/M9A_Kaktusowa.pdf',
      ...await generateAreaProperties('M9A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [1163, 305, 1291, 305, 1293, 94, 1512, 94, 1512, 360, 1374, 363, 1379, 433, 1301, 433, 1298, 363, 1214, 362, 1167, 363],
    },
    {
      id: 'M8A',
      title: 'M8A',
      shape: 'poly',
      name: '8',
      href: 'flats/M8A_Kaktusowa.pdf',
      ...await generateAreaProperties('M8A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [1077, 367, 1170, 367, 1172, 310, 1296, 309, 1301, 93, 1073, 93],
    },
    {
      id: 'M7A',
      title: 'M7A',
      shape: 'rect',
      name: '7',
      href: 'flats/M7A_Kaktusowa.pdf',
      ...await generateAreaProperties('M7A'),
      strokeColor: 'rgba(0, 0, 0, 0)',
      coords: [881, 94, 1075, 366],
    },
  ],
};

export default groundFloor;

