import React from 'react';
import './brand.css';
import { fetchEndDate } from '../../utils/firebaseService';

const endDate = await fetchEndDate();

const Brand = () => (
  <div className="fp__brand">
    <div>
      <i className="fa-solid fa-city" />
      <p>3 etapy</p>
    </div>
    <div>
      <i className="fa-solid fa-square-parking" />
      <p> Parking podziemny - 37 miejsc</p>
    </div>
    <div>
      <i className="fa-solid fa-coins" />
      <p>Ceny już od 0000 zł/m²</p>
    </div>
    <div>
      <i className="fa-solid fa-maximize" />
      <p>Materaże od 31 m² do 98 m²</p>
    </div>
    <div>
      <i className="fa-solid fa-tree-city" />
      <p>Łącznie 105 mieszkań w 3 etapach</p>
    </div>
    <div>
      <i className="fa-solid fa-clock-rotate-left" />
      <p>Planowany termin zakończenia inwestycji {endDate}</p>
    </div>
    <div>
      <i className="fa-brands fa-pagelines" />
      <p>Zielona spokojna okolica</p>
    </div>
    <div>
      <i className="fa-solid fa-people-roof" />
      <p>Mieszkania 1 2 3 i 4 pokojowe</p>
    </div>
    <div>
      <i className="fa-solid fa-bus-simple" />
      <p>W poblizu przystanek autobusowy</p>
    </div>
  </div>
);

export default Brand;
